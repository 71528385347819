<div  class="contenedor" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo *ngIf="muestraContenido">
    <div class="headerPage">
        <ul>
            <li>
                <img src="data:image/gif;base64,{{miEstablecimiento.strLogo}}">
            </li>
            <li>{{miEstablecimiento.strNombre}} {{miEstablecimiento.strTelefono1}} {{miEstablecimiento.strTelefono2}} {{miEstablecimiento.strDireccion}} 
                {{miEstablecimiento.strPoblacion}} {{miEstablecimiento.strCp}} {{miEstablecimiento.strProvincia}}</li>
        </ul>
    </div>
    <br>
    <div class="pageContent" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>

        <ul class="formularioData">
            <form [formGroup]="formNewRegistro">
            <li>
                <mat-form-field>
                <input matInput formControlName="nombre" [(ngModel)]="registro.strNombre" name="strNombre" placeholder="Nombre">
                <!-- <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.nombre.hasError('required')">*Campo necesario</mat-hint> -->
                </mat-form-field>
            </li>
            <li>
                <mat-form-field>
                <input matInput formControlName="apellido" [(ngModel)]="registro.strApellidos" mame="strApellidos" placeholder="Apellido">
                <!-- <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.apellido.hasError('required')">*Campo necesario</mat-hint> -->
                </mat-form-field>
            </li>
            <li>
                <mat-form-field>
                <input matInput formControlName="dni" [(ngModel)]="registro.strDni" name="strDni" placeholder="D.N.I.">
                <!-- <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.dni.hasError('required')">*Campo necesario</mat-hint> -->
                <!-- <mat-hint class="formatError" style="color: red;" *ngIf="submitted && dniError">*Formato no válido</mat-hint> -->
                </mat-form-field>
            </li>
            <li>
                <mat-form-field>
                <input matInput formControlName="telefono" [(ngModel)]="registro.strTel1" name="strTel1" placeholder="Teléfono" required>
                <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.telefono.hasError('required')">*Campo necesario</mat-hint>
                </mat-form-field>
            </li>
            <li>
                <mat-form-field>
                <input matInput formControlName="direccion" [(ngModel)]="registro.strDireccion" name="strDireccion" placeholder="Dirección">
                </mat-form-field>
            </li>
            <li>
                <mat-form-field>
                <input matInput formControlName="email" [(ngModel)]="registro.strEmail" name="strEmail" placeholder="Email" required>
                <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.email.hasError('required')">*Campo necesario</mat-hint>
                <mat-hint class="formatError" style="color: red;" *ngIf="submitted && formNewRegistro.controls.email.hasError('pattern')">Formato no válido</mat-hint>
                </mat-form-field>
            </li>
            </form>
        </ul>
        <br>
    </div>
    
    <div class="pageContent" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>
        <div class="box">

    
            <input type="checkbox" id="checkBoxReservation" [(ngModel)]="checked">
            <span class="wrap-mat-radio-label"> &nbsp; <label for="checkBoxReservation"> Acepto la </label> 
                <a (click)="verPoliticaPrivacidad()"> política de privacidad.</a>
            </span>
        </div>
        <br>
    </div>
    
    <div class="pageContent" [style.background-color]=colorCuerpo [style.color]=colorTextoCuerpo>
        <div class="box" style="border: none; text-align: right;">
            <button mat-raised-button (click)="enviar()" class="btn-confirm">Enviar</button>
        </div>
    </div>
    
</div>

<section class="contentLoader" *ngIf="showLoader">
    <svg viewBox="-2000 -1000 4000 2000" *ngIf="showInfi">
        <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z">
        </path>
        <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px">
        </use>
      </svg>

      <div style="position: relative;" *ngIf="showError">
        <div style="width: 80%; height: 30%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 18px; font-weight: 600; color:red">
          {{strMensajeError}}
          <!-- <br><br>
            <button mat-raised-button color="warn" (click)="Aceptar()" style="font-weight: 600;">Reintentar</button> -->
        </div>
      </div>
</section>