<!-- <section>
    <img src="../../../assets/high-priority.png">
  </section>
  <br>
  <section>
    {{data.texto}}
  </section>
  <br>
  <section>
    <div class="agregar">
      <button mat-raised-button (click)="pulsa()" class="btn-confirm">Aceptar</button>
      <button mat-raised-button (click)="pulsa2()" class="btn-cancel" *ngIf="dosAcciones">Cancelar</button>
    </div>
  </section> -->

  <section>
    <img src="../../../assets/ok.png" *ngIf="muestraOk">
    <img src="../../../assets/error.png" *ngIf="muestraError">
  </section>
  <br>
  <section>
    {{data.texto}}
  </section>
  <section>
    {{data.texto2}}
  </section>
  <br>
  <section>
    <div class="agregar">
      <button mat-raised-button (click)="pulsa()" class="btn-confirm">Aceptar</button>
      <button mat-raised-button (click)="pulsa2()" class="btn-cancel" *ngIf="dosAcciones">Cancelar</button>
    </div>
  </section>