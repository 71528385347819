export class RegistroCovid {

    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strTel1?:string;
    public strDireccion?:string;
    public strEmail?:string;

}
