import { Component, OnInit, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NavItem } from 'src/app/services/nav-item';
import { MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {

  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  // @Input() item: Grupo;
  @Input() item: any;
  @Input() depth: number;
  @Output() itemId = new EventEmitter<any>();
  strCookie:string;
  showSpinner=false;

  constructor(private storageService: StorageService, private dialog: MatDialog,
              public router: Router, private route:ActivatedRoute) {
    if (this.depth === undefined) {
      this.depth = 0;
    }

    this.route.queryParams.subscribe(params => {
      // console.log(params["coo"])
      this.strCookie=params["coo"];
    })
  }

  ngOnInit() {
    // this.navService.currentUrl.subscribe((url: string) => {
    //   // if (this.item.route && url) {
    //   //   this.expanded = url.indexOf(`/${this.item.route}`) === 0;
    //   //   this.ariaExpanded = this.expanded;
    //   // }
    // });
  }

  onItemSelected(item: any) {
    /*
    // console.log(item)
    // if (!item.miListGrupoSub1 || !item.miListGrupoSub1.length) {
    //   // this.router.navigate([item.route]);
    //   this.navService.closeNav();
    // }
    if (item.miListGrupoSub1 && item.miListGrupoSub1.length) {
      this.expanded = !this.expanded;
    }
    */

    // console.log(item)

    if(item.miListGrupoSub1!=undefined){
      if(this.storageService.isAuthenticated()){
        this.router.navigate(['./menu/carta', item.intId, 0]);
      }else{
        let extra: NavigationExtras = {
          queryParams: {
            'coo':this.strCookie
          }
        }
  
        // console.log(this.strCookie)
        this.router.navigate(['./menu/carta', item.intId, 0], extra)
      }
    }
    
  }

  clickChield(e){
    // console.log(e)
    // this.showSpinner=true;
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/carta', e.intIdGrupo, e.intId]);
      // this.showSpinner=false;
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }

      // console.log(this.strCookie)
      this.router.navigate(['./menu/carta', e.intIdGrupo, e.intId], extra)
      // this.showSpinner=false;
    }
    
  }

}
