import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestService } from './services/rest.service';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { LoginService } from './services/login.service';
import { DatePipe } from '@angular/common';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { PoliticaCondicionesEnvioFacturacionComponent } from './components/politica-condiciones-envio-facturacion/politica-condiciones-envio-facturacion.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { APIResolver } from './services/resolve';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormularioComponent } from './components/formulario/formulario.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    VentanaEmergenteComponent,
    SidenavComponent,
    LayoutComponent,
    LoginLayoutComponent,
    PoliticaCondicionesEnvioFacturacionComponent,
    MenuListItemComponent,
    PoliticaCookiesComponent,
    FooterComponent,
    FormularioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,


    MatNativeDateModule
  ],
  providers: [RestService, APIResolver, StorageService, LoginService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe
              
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
