import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { MenuComponent } from './components/menu/menu.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { PoliticaCondicionesEnvioFacturacionComponent } from './components/politica-condiciones-envio-facturacion/politica-condiciones-envio-facturacion.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { APIResolver } from './services/resolve';
import { AuthGuard } from './guards/authGuard';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormularioComponent } from './components/formulario/formulario.component';


const routes: Routes = [
  /** LISTA DE GENERAL DE COMPONENTES **/
  { path: 'ventana-emergente', component: VentanaEmergenteComponent },

  { path: 'menu', component: MenuComponent},
  { path: 'menu-item', component: MenuListItemComponent},

  /** COMPONENTES NUEVOS **/
  { path: 'sidenav', component: SidenavComponent},
  // { path: 'sidenav-cliente', component: SidenavClienteComponent},

  {
    path: 'inicio',
    component: LoginLayoutComponent,
    children: [
      { path: 'formulario', component: FormularioComponent },
    ]
  },

  {
    path: 'menu',
    component: LayoutComponent,
    children: [
      // { path: 'carta/:intGrupo/:intId', component: CartaComponent},
      // { path: 'carta', component: CartaComponent},
      // { path: 'detalle/:intArtId', component: DetalleArticuloComponent },
      // { path: 'detalle', component: DetalleArticuloComponent },
      { path: 'politica-cookies', component: PoliticaCookiesComponent },
    ]
  },

  { path: 'footer', component: FooterComponent },

  /** PERMISOS PARA COMPONENTES - GUARDS ***/
  /** COMPONENTES POR DEFECTO **/
  { path: '', redirectTo: '/inicio/formulario', pathMatch: 'full' },
  { path: '**', redirectTo: '/inicio/formulario', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
