import { Component, OnInit, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav, MatDialog } from '@angular/material';
import { StorageService } from 'src/app/services/storage.service';
import { NavItem } from 'src/app/services/nav-item';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { EventEmiterService } from 'src/app/services/nav.service';
import { LoginService } from 'src/app/services/login.service';
import { FormControl } from '@angular/forms';
import { Establecimiento } from 'src/app/models/establecimiento';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  // fillerNav = Array(50).fill(0).map((_, i) => `Nav Item ${i + 1}`);

  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  totalArticulos:number=0;
  breakpoint:any;
  notLogIn:boolean;
  LogIn:boolean;
  inicialUser:string;

  //LOGO
  textoApp:string;
  imgLogo:string;

  miGupoResult:any;
  intId:any;
  strCookie:string;
  showContent=false;
  showDiv=true;
  showSpinner=false;

  nombreEst:string;
  strDireccion:string;

  miEstablecimiento:Establecimiento=new Establecimiento();
 
   onResize(event) {
     this.breakpoint = (event.target.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;
   }
 
   mouseenter() {
     if (!this.isExpanded) {
       this.isShowing = true;
     }
   }
 
   mouseleave() {
     if (!this.isExpanded) {
       this.isShowing = false;
     }
   }

  constructor(private storageService: StorageService,private miLoginService:LoginService, private dialog: MatDialog, private router:Router,private route: ActivatedRoute,
    ) {
    this.route.queryParams.subscribe(params => {
      this.strCookie=params["coo"];
    });
  }

  ngOnInit() {
    this.showSpinner=true;
    this.breakpoint = (window.innerWidth <= 1000 && window.innerWidth > 375) ? 2 : 5 && (window.innerWidth <= 375) ? 1 : 5;
    
    if(this.storageService.isAuthenticated()){
      this.strCookie=this.storageService.getCurrentSession().strCookie;

      var event = new Date(this.storageService.getCurrentSession().miToken[".expires"]);
      var jsonDate = event.toJSON();
      var today = new FormControl(new Date()).value.toJSON();
      if(jsonDate>today){
      }else if(jsonDate<today){
        setTimeout(() => {
          this.openWindow();
        }, 100); 
      } 

      this.nombreEst=this.storageService.getCurrentSession().strEstablecimientoNombre;
      this.notLogIn=false;
      this.LogIn=true;
      this.inicialUser=this.storageService.getCurrentUser().strUserName.charAt(0).toUpperCase()

      this.textoApp=this.storageService.getCurrentSession().strNombreApp;
      this.imgLogo=this.storageService.getCurrentSession().strLogo;
      this.listarGrupos(this.storageService.getCurrentSession().strCookie);
    }else{
      //Recupero los datos del establecimiento
      
      //Recupero datos producto
      this.recuperaProducto();
      this.notLogIn=true;
      this.LogIn=false;
      this.listarGrupos(this.strCookie);
    }

    //Recupero los datos del establecimiento para el pie de pagina
    this.recuperaDatosGeneralesEstablecimientos();
    
  }

  openWindow(){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:"Tu sesion ha caducado...",
        boton:false,
        btnError:true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.logout();
    });
  }

  recuperaProducto(){
    this.miLoginService.getLogo(this.strCookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.textoApp=datos.miListProducto[0].strNombre;
        // this.imgLogo=datos.miListProducto[0].strLogoBase64;
        // this.nombreEst=datos.miListProducto[0].strNombreEstablecimiento;
      }
    })
  }

  recuperaDatosGeneralesEstablecimientos(){
    this.miLoginService.getDatosGeneralesEst(this.strCookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.imgLogo=datos.miListEstablecimiento[0].strLogo;
        this.nombreEst=datos.miListEstablecimiento[0].strNombre;
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }
      }
    })
  }

  verDestacados(){
    if(this.storageService.isAuthenticated()){
      this.router.navigate(['./menu/carta']);
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'coo':this.strCookie
        }
      }
      this.router.navigate(['./menu/carta'], extra)
    }
  }

  areaCliente(){
    this.router.navigate(['/cliente/area-cliente'])
  }

  logearse(){
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.strCookie
      }
    }

    this.router.navigate(['./inicio/login'], extra)
  }

  listarGrupos(cookie){
    // this.miServicio.getGrupos(cookie).subscribe(datos=>{
    //   // console.log(datos)
    //   if(datos.miRespuesta.booOk){
    //     this.showSpinner=false;
    //     this.miGupoResult=datos;
    //     this.showContent=true;
    //     this.showDiv=false;
    //     // console.log(this.miGupoResult);
    //   }else{
    //     let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
    //       width: '600px',
    //       data:{
    //         texto:"Se ha producido un error, intentelo mas tarde.",
    //         texto2:"",
    //         boton:false,
    //         btnError:true,
    //         btnOK:false
    //       }
    //     });
    //   }
    // })
  }

  parentWillTakeAction($event){
    this.intId=$event;
  }

  public logout(): void{
    // this.showSpinner = true;
    this.storageService.logout();
  }


}
