import { Component, OnInit } from '@angular/core';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegistroCovid } from 'src/app/models/registro-covid';
import { RestService } from 'src/app/services/rest.service';
import { Establecimiento } from 'src/app/models/establecimiento';
import { flatten } from '@angular/compiler';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  checked=false;
  showInfi=true;
  showError=false;
  strMensajeError:string;
  muestraContenido=false;
  formNewRegistro: FormGroup;
  submitted=false;
  registro:RegistroCovid=new RegistroCovid();
  showLoader=true;

  cookie:string;
  mesa:string;
  idEstablecimiento:string="";
  idProducto:string="";

  colorCuerpo="";
  colorTextoCuerpo="";

  miEstablecimiento:Establecimiento=new Establecimiento();

  dniError=false;

  constructor(private dialog: MatDialog,private router:Router,private _formBuilder: FormBuilder,private route: ActivatedRoute, private miservicio:RestService) {
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      this.mesa=params["mes"];
      this.idEstablecimiento=params["est"];
      this.idProducto=params["pro"];
    });
   }

  ngOnInit() {
    if(this.cookie!=undefined && this.cookie!=""){
      //Pido datos establecimiento
      this.getEst();
    }else{
      //No tiene Cookie
      this.windowNotif("No se ha podido obtener la cookie.","",false,true,false);
    }
    this.formNewRegistro = this._formBuilder.group({
      nombre: ["",
        // Validators.required
      ],
      apellido: ["",
        // Validators.required
      ],
      dni: ["",
        // Validators.required
      ],
      telefono: [Validators.required],
      direccion: [""],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
    })
  }

  getEst(){
    this.miservicio.getEst(this.cookie).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)
        this.colorCuerpo="#"+datos.miListEstablecimiento[0].strColorCuerpo;
        this.colorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo;
        
        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }

        this.muestraContenido=true;
        this.showInfi=false;
        this.showLoader=false;

      }else{
        this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false);
        this.showError=true;
        this.strMensajeError=datos.miRespuesta.strMensaje;
      }
    })
  }
  verPoliticaPrivacidad(){
    this.windowNotif("Política privaciad "+this.miEstablecimiento.strNombre,this.miEstablecimiento.strPoliticaPrivacidad,false,false,false);
  }

  enviar(){
    this.submitted=true;
    if(this.formNewRegistro.valid){
      this.showLoader=true;
      // if(this.nif()){
        this.dniError=false;
        if(!this.checked){
          this.windowNotif("Antes debe aceptar la política de privacidad.","",false,true,false);
        }else{
          // console.log(this.registro)
          this.miservicio.postClienteCovid(this.registro,this.cookie).subscribe(datos=>{
            // console.log(datos)
            this.showLoader=false;
            if(datos.booOk){
              this.windowNotif(datos.strMensaje,"",false,false,true);
            }else{
              this.windowNotif(datos.strMensaje,"",false,true,false);
            }
          })
        }
      // }
      // else{
      //   this.dniError=true;
      // }
    }
  }

  private  nif() {
    var numero
    var letr
    var letra
  
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewRegistro.controls.dni.value) == true){
        numero = this.formNewRegistro.controls.dni.value.substr(0,this.formNewRegistro.controls.dni.value.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = this.formNewRegistro.controls.dni.value.substr(this.formNewRegistro.controls.dni.value.length-1,1);
        numero = numero % 23;
        letra='TRWAGMYFPDXBNJZSQVHLCKET';
        letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        return false;
       }else{
         return true;
       }
    }else{
      return false;
     }
  }

  windowNotif(mesaje,mensaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mensaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
  }

}
